import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Skype',
    useCase: 'INDUSTRIAL DESIGN, UX DESIGN, SOFTWARE DEVELOPMENT CONSULTING',
    description: `Lead the industrial design on the Skype (Vostok 1.0) camera system.
    Designed beta Vostok 2.0 and SkypeBOX concepts to enable a more centralized viewing experience in the home.
    Designed initial UX around streaming the camera system to a TV, recognizing faces, pairing with multiple speaker devices and sharing your screen for streaming movies and videos. Tested voice activation from Alexa, Google Home and Cortana to call on skype from in home devices to desktop and mobile devices.`,
  },
  caseId: 'skype',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
